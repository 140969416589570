// palette for Tradition Casino
$bg-color: #14002a;
$text-color: #fffdeb;
//$primary: #c1966c;
$primary: #ffb724;
$success: #48D995;
$info: #6DA9C2;
$danger: #F23D3D;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

body {
  font-family: "Open Sans", #{ --bs-font-sans-serif } !important;
}


.sticky-sidebar {
  .nav-link:hover {
    color: $primary !important;
    .casino-icon path {
      fill: $primary !important;
    }
  }
  .active-link {
    color: $primary !important;
    .casino-icon {
      opacity: 1;
      path {
        fill: $primary !important;
      }
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}
